// import { transformProduct } from 'helpers/products.js';
import { transformProduct } from '@hultafors/snickers/product-helpers';
import { SnickersProduct } from '@hultafors/snickers/types';

/**
 * @desc Toggles a favorite product. Product is transformed to only needed properties.
 * @param {object} product - product representation from list view or detail page
 * @param {boolean} isFavorite - true would indicate product is already a favorite
 * @return {array} favorites
 */
export const toggleFavorite = ({
  items,
  product,
  isFavorite,
}: {
  items: Partial<SnickersProduct>[];
  product: SnickersProduct;
  isFavorite: boolean;
}): Partial<SnickersProduct>[] => {
  if (items) {
    if (isFavorite) {
      return removeFromFavorites(items, product);
    } else {
      return addToFavorites(items, product);
    }
  } else {
    return addToFavorites(items, product);
  }
};

const addToFavorites = (
  items: Partial<SnickersProduct>[],
  product: SnickersProduct,
): Partial<SnickersProduct>[] => {
  if (!product) {
    return items ? [...items] : [];
  }

  if (items) {
    const exist = items.find((x) => x.sku === product.sku);
    if (!exist) {
      const transformedProduct = transformProduct(product);
      if (transformedProduct) {
        return [...items, transformedProduct];
      }
    } else {
      return [...items];
    }
  } else {
    // Add first item in favorites
    const transformedProduct = transformProduct(product);
    if (transformedProduct) {
      return [transformedProduct];
    }
  }

  return [];
};

/**
 * @desc Takes array of products presumably from local storage. Removes second param product from array and return it
 * @private Public for tests only
 * @param {array} items
 * @param {object} product
 * @returns {array} cart items
 */
export const removeFromFavorites = (
  items: Partial<SnickersProduct>[],
  product: SnickersProduct,
): Partial<SnickersProduct>[] => {
  return items
    ? product?.sku
      ? items.filter((x) => x.sku !== product.sku)
      : [...items]
    : [];
};

/**
 * @desc Checks id product is in favorites list ( local storage )
 * @param string sku - product sku
 * @param array items - list of favorites
 * @return boolean
 */
export const isProductFavorite = (
  sku: string,
  items: SnickersProduct[],
): boolean => (items?.find((x) => x.sku === sku) ? true : false);
