import { ParsedProduct } from '@hultafors/shared/types';

import { SnickersProduct } from '@hultafors/snickers/types';
import { ProductListData } from '@hultafors/snickers/types';

export const transformListPageProduct = (
  product: SnickersProduct,
): Partial<SnickersProduct> => {
  // TODO how does isNew work in "detail vs list"

  return {
    category: product.categorySlug || '',
    categorySlug: product.categorySlug || '',
    image: product.image,
    isNew: !!product.isNew,
    isPremium: product?.attributes?.[0]?.label === 'PREMIUM',
    name: product.name,
    parentSlug: product.parentSlug,
    productId: product.productId || '',
    sku: product.sku,
  };
};

export const transformListPageProductCart = (
  product: ProductListData,
): Partial<ParsedProduct> => {
  // TODO how does isNew work in "detail vs list"

  return {
    category: product.categorySlug || '',
    image: product.image,
    isNew: !!product.isNew,
    isPremium: product?.attributes?.[0]?.label === 'PREMIUM',
    name: product.name,
    productId: product.productId || '',
    sku: product.sku,
  };
};
