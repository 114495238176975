import {
  ProductDetailData,
  ProductListData,
  SnickersProduct,
} from '@hultafors/snickers/types';

import {
  transformDetailPageProduct,
  transformDetailPageProductCart,
} from './transformDetailPageProduct';
import {
  transformListPageProduct,
  transformListPageProductCart,
} from './transformListPageProduct';

/**
 * @desc Checks if product parameter comes from list view or detail page
 * @param object product
 * @return boolean
 */
const isDetailPageProduct = (product: SnickersProduct) => {
  // Detail page products has different structure than list view product
  return typeof product.sku === 'string' ? false : true;
};

const isDetailPageProductCart = (
  product: ProductListData | ProductDetailData,
) => {
  // Detail page products has different structure than list view product
  return typeof product.sku === 'string' ? false : true;
};

/**
 * @desc Transforms "list view" or "detail page" product to minimal representation needed for favorites, cart and auto complete search
 * @param object product
 * @return object (product representation)
 */
export const transformProduct = (product: SnickersProduct) =>
  isDetailPageProduct(product)
    ? transformDetailPageProduct(product)
    : transformListPageProduct(product);

export const transformProductCart = (
  product: ProductListData | ProductDetailData,
) =>
  isDetailPageProductCart(product)
    ? transformDetailPageProductCart(product as ProductDetailData)
    : transformListPageProductCart(product as ProductListData);
