import { ParsedProduct } from '@hultafors/shared/types';

import { SnickersProduct } from '@hultafors/snickers/types';
import { ProductDetailData } from '@hultafors/snickers/types';

import { getFirstImage } from './getFirstImage';

export const transformDetailPageProduct = (
  product: SnickersProduct,
): Partial<SnickersProduct> => {
  // TODO how does isNew work in "detail vs list"
  const isNew = product.isNew ? true : false;

  return {
    categorySlug: product.categorySlug,
    image: product.image,
    isNew: isNew,
    name: product.name,
    parentSlug: product.parentSlug,
    productId: product.productId,
    sku: product.sku,
  };
};

export const transformDetailPageProductCart = (
  product: ProductDetailData,
): Partial<ParsedProduct> => {
  // TODO how does isNew work in "detail vs list"
  const isNew = product.isNew ? true : false;
  return {
    categorySlug: product.categorySlug,
    image: getFirstImage(product) || undefined,
    isNew: isNew,
    name: product.name.value,
    productId: product.id,
    sku: product.sku.value,
  };
};
